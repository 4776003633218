<div class="modal-content">
	<div class="modal-header">
		<h5 class="modal-title">Invia notifica push</h5>
		<button
			type="button"
			class="close"
			data-dismiss="modal"
			(click)="dismiss()"
			aria-label="Close">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<form
			#editForm="ngForm"
			(ngSubmit)="editForm.valid && submit()">
			<div class="row form-group">
				<div class="col-md-12">
					<div class="input-group">
						<input
							id="alls"
							class="bootstrap"
							name="alls"
							[(ngModel)]="all"
							#alls="ngModel"
							type="checkbox">
						<label
							class="check-label mt-2 ml-2"
							for="alls">
							{{'labels.all' | translate}}
						</label>
					</div>
				</div>
				<div class="col-md-6">
					<label>{{'labels.user' | translate}}</label>
					<app-entity-autocomplete
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="userSearch"
						[entityClass]="userClass"
						name="utente"
						[disabled]="all || genericSearch"
						[(ngModel)]="selectedUser"
						placeholder="{{ 'labels.user' | translate }}">
					</app-entity-autocomplete>
				</div>
				<div class="col-md-6">
					<label>{{'labels.title' | translate}}</label>
					<div class="input-group">
						<input
							[(ngModel)]="title"
							#titles="ngModel"
							name="titles"
							type="text"
							required
							class="form-control"
							maxlength="32"
							id="titles"
							placeholder="{{ 'labels.title' | translate}}"
							[ngClass]="{'is-invalid': editForm.submitted && titles.invalid}">
					</div>
				</div>
				<div class="col-md-12">
					<label>{{'labels.message' | translate}}</label>
					<textarea
						#messages="ngModel"
						name="messages"
						rows="3"
						[maxlength]="500"
						class="form-control"
						id="messages"
						[(ngModel)]="message"
						[ngClass]="{'is-invalid': editForm.submitted && messages.invalid}"></textarea>
				</div>		
				<div class="col-md-12 bg-info mt-3">
					<label><b>Esempi link</b></label>
					<div class="row"> 
						Entra nel viaggio... {{ '{' }}"link":"https://app.meetravel.it/travel/17765945b5-187e3e-184d75-82b61b-18cf8cb026194f"}
					</div>
					<div class="row"> 
						Crea un viaggio {{ '{' }}"link":"https://app.meetravel.it/newTravel"}
					</div>
					<div class="row"> 
						Vai sul profilo di … {{ '{' }}"link":"https://app.meetravel.it/profile/31013"}
					</div>
					<div class="row"> 
						Lascia un feedback per questo viaggio {{ '{' }}"link":"https://app.meetravel.it/feedback/17765945b5-187e3e-184d75-82b61b-18cf8cb026194f"}
					</div>
					<div class="row"> 
						Entra nella chat! {{ '{' }}"link":"https://app.meetravel.it/chat/17765945b5-187e3e-184d75-82b61b-18cf8cb026194f"}
					</div>
					<div class="row"> 
						Vai su google {{ '{' }}"link":"https://www.google.it"}
					</div>
				</div>
			</div>

			<div class="modal-footer">
				<button class="btn btn-primary">Invia</button>
				<button
					(click)="dismiss()"
					type="button"
					class="btn btn-secondary">
					{{'labels.close' | translate}}
				</button>
			</div>
		</form>
	</div>
</div>
