import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, ListFiltersComponent } from 'elbuild-ui-componentslib';
import { CrudService, download, MessageService, Search, SortDirection, User } from 'elbuild-ui-lib-core';
import { CommonService } from 'meetravel-ui-lib';
import { TravelTransaction } from 'src/app/@core/model/travel-transaction.model';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';


@Component({
	selector: 'smart-transactions-list',
	templateUrl: './smart-transactions-list.component.html',
	styleUrls: ['./smart-transactions-list.component.scss']
})
export class SmartTransactionsListComponent implements OnInit {

	loading: boolean = false;
	advancedSearch: Search = new Search('traveltransaction', 'concat(id, travelId)', 'traveltransaction');
	showAdvancedSearch: boolean = true;
	loggedUser: User;
	rows: TravelTransaction[] = [];
	exporting: boolean;

	@ViewChild('listfilters', {}) listfilters: ListFiltersComponent;

	constructor(
		private crudService: CrudService<TravelTransaction>,
		private messageService: MessageService,
		private modalService: NgbModal,
		private dialogService: DialogService,
		private commonService: CommonService,
		private translateService: TranslateService,
		private clipboard: Clipboard,
		private cdr: ChangeDetectorRef) {
		this.advancedSearch.setSortDirection(SortDirection.DESCENDING);
		this.advancedSearch.setSortField('id');
		this.advancedSearch.addNotEqualFilter('status', 'pending');
	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		const urlparamsreq = this.listfilters.readParams().subscribe(data => {
			if (data[1]) {
				this.loggedUser = data[1];
			}
			this.search(data[0]);
		});
	}

	onPageChange(pageInfo: any) {
		this.advancedSearch.page.setPageNumber(pageInfo.offset);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	onSortChange(sortInfo: any) {
		const sort = sortInfo.sorts[0];
		this.advancedSearch.setSortField(sort.prop);
		this.advancedSearch.setSortDirection(sort.dir);
		this.listfilters.addQueryParams();
		this.loadItems();
	}

	rowClass = (row: any) => {
		return '';
	}

	get page() {
		if (this.advancedSearch) {
			return this.advancedSearch.page;
		} else {
			return undefined;
		}
	}

	search(searchParams?: any) {
		if (searchParams) {
			this.advancedSearch = searchParams;

		}
		this.loadItems();
	}

	loadItems() {
		this.loading = true;
		this.rows = [];
		this.cdr.detectChanges();

		const datarequestTransaction = this.crudService.searchEntities(TravelTransaction, 'traveltransaction', this.advancedSearch, this.advancedSearch.page);
		const countrequestTransaction = this.crudService.countEntities(TravelTransaction, 'traveltransaction', this.advancedSearch);
		forkJoin([datarequestTransaction, countrequestTransaction]).subscribe(results => {
			this.rows = results[0];
			console.log(this.rows);
			this.advancedSearch.page.setTotal(results[1].ct);
			this.loading = false;
			this.cdr.detectChanges();
		}
		);
	}


	// copy(row: TravelTransaction) {
	// 	if (row && row.receipt) {
	// 		this.clipboard.copy(row.receipt);
	// 		this.messageService.sendSuccess(
	// 			this.translateService.instant('labels.receipt-copied'),
	// 			this.translateService.instant('labels.success')
	// 		);
	// 	}
	// }

	toggleCheckbox(row: TravelTransaction) {
		row.checked = !row.checked;
		this.crudService.saveEntity(TravelTransaction, 'traveltransaction', row).subscribe(result => {
			this.messageService.sendSuccess(this.translateService.instant('labels.success-saved-purchase'), this.translateService.instant('labels.purchase'));
		}, error => {
			this.messageService.sendError(error);
		});
	}

	export() {
		this.exporting = true;
		this.commonService.export(this.advancedSearch).subscribe(res => {
			this.exporting = false;
			const filename = this.advancedSearch.table + '_' + moment().format('DDMMyyyy') + '.xlsx';
			download(res, filename);
		}, error => {
			this.messageService.sendError(error);
			this.exporting = false;
		});
	}

}
