import { MeetravelUser } from 'meetravel-ui-lib';

export class MeetravelUser1 extends MeetravelUser{

    promotionConsent: boolean;
    customPrefConsent: boolean;
    billingData: boolean;
    iban: string;
    billingAddress: string;
    fiscalCode: string;
}