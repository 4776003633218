<div class="card">
	<div class="card-body">
		<form
			*ngIf="_travel"
			#editForm="ngForm"
			[formReset]="editForm"
			(ngSubmit)="editForm.valid && submit()">

			<div class="row">				
				<div class="col-md-12">
					Opaque id ( da utilizzare nei link per notifiche): {{_travel.opaqueid}}
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-md-3">
					<h5>
						In Chat: {{_travel.currentguests}}/{{_travel.maxguests}}
					</h5>
				</div>
				<div
					class="col-md-3"
					*ngIf="_travel.ratingavg">
					<h5>Rating Medio: {{_travel.ratingavg}}</h5>
				</div>
				<div
					class="col-md-3"
					*ngIf="_travel.premium">
					<h5 class="text-primary">Premium</h5>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.name' | translate }}*</label>
					<input
						[(ngModel)]="_travel.name"
						#name="ngModel"
						name="name"
						required
						type="text"
						class="form-control"
						id="name"
						placeholder="{{ 'labels.name' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && name.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && name.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<div class="form-group col-md-3">
					<label for="destination">{{ 'labels.destination' | translate }}*</label>
					<app-entity-autocomplete
						entityName="destination"
						entityField="name"
						entityFilterField="name"
						entityFilterType="like"
						[entitySearch]="destinationSearch"
						[entityClass]="destinationClass"
						(onSelected)="onSelectedDestination($event)"
						(onDelete)="onDeleteDestination()"
						name="destination"
						[(ngModel)]="destinationSelected"
						placeholder="{{ 'labels.destination' | translate }}">
					</app-entity-autocomplete>
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && name.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<div class="col-md-2 form-group">
					<label>{{'labels.organizer' | translate}}</label>
					<span class="float-right">
						<i
							[routerLink]="['/user/edit/'+_travel.hostid]"
							class="fas fa-external-link-alt"></i>
					</span>
					<app-entity-autocomplete
						[disabled]="true"
						entityName="user"
						entityField="fullname"
						entityFilterField="concat(fname, ' ', lname)"
						entityFilterType="like"
						[entitySearch]="searchUsers"
						[entityClass]="userClass"
						(onSelected)="onSelectedOrganizer($event)"
						(onDelete)="onDeleteOrganizer()"
						name="customer"
						[(ngModel)]="userSelected"
						placeholder="{{ 'labels.organizer' | translate }}">
					</app-entity-autocomplete>

				</div>
				<div class="form-group col-md-2">
					<label for="name">{{ 'labels.status' | translate }}*</label>
					<span
						*ngIf="_travel.status === 'deleted'"
						class="ml-2">
						<i
							(click)="showDeleteNote(_travel)"
							ngbTooltip="{{_travel.deletereason}} {{_travel.deletereasonother}}"
							class="fas fa-exclamation-triangle"></i>
					</span>
					<select
						[(ngModel)]="_travel.status"
						#status="ngModel"
						name="status"
						required
						class="form-control"
						id="status"
						[disabled]="true"
						[ngClass]="{'is-invalid': editForm.submitted && status.invalid}">
						<option
							*ngFor="let status of statuses"
							[value]="status">
							{{'types.travel-status.'+status | translate}}
						</option>
					</select>
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && maxguests.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<div class="form-group col-md-1 margineditstatus">
					<a (click)="openStatusEditModal()">
						<i
							container="body"
							ngbTooltip="{{'labels.edit' | translate}}"
							class="feather icon-edit"></i>
					</a>
				</div>
				<div class="form-group col-md-1">
					<label for="name">{{ 'labels.smart' | translate }}</label>
					<div class="row">
						<span class="ml-3">
							{{(_travel.smart ? 'labels.yes':'labels.no') | translate}}
						</span>
					</div>
				</div>

			</div>
			<div class="row">
				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.budgetmin' | translate }}*</label>
					<input
						[(ngModel)]="_travel.budgetmin"
						#budgetmin="ngModel"
						name="budgetmin"
						required
						type="number"
						class="form-control"
						id="budgetmin"
						placeholder="{{ 'labels.budgetmin' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && budgetmin.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && budgetmin.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.budgetmax' | translate }}*</label>
					<input
						[(ngModel)]="_travel.budgetmax"
						#budgetmax="ngModel"
						name="budgetmax"
						required
						type="number"
						class="form-control"
						id="budgetmax"
						placeholder="{{ 'labels.budgetmax' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && budgetmax.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && budgetmax.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.agemin' | translate }}*</label>
					<input
						[(ngModel)]="_travel.agemin"
						#agemin="ngModel"
						name="agemin"
						required
						type="number"
						class="form-control"
						id="agemin"
						placeholder="{{ 'labels.agemin' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && agemin.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && agemin.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.agemax' | translate }}*</label>
					<input
						[(ngModel)]="_travel.agemax"
						#agemax="ngModel"
						name="agemax"
						required
						type="number"
						class="form-control"
						id="agemax"
						placeholder="{{ 'labels.agemax' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && agemax.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && agemax.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>

				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.maxguests' | translate }}*</label>
					<input
						[(ngModel)]="_travel.maxguests"
						#maxguests="ngModel"
						name="maxguests"
						required
						type="number"
						class="form-control"
						id="maxguests"
						placeholder="{{ 'labels.maxguests' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && maxguests.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && maxguests.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>

				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.maxgenderm' | translate }}</label>
					<input
						[(ngModel)]="_travel.maxgenderm"
						#maxgenderm="ngModel"
						name="maxgenderm"
						type="number"
						class="form-control"
						id="maxgenderm"
						placeholder="{{ 'labels.maxgenderm' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && maxgenderm.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && maxgenderm.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>

				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.maxgenderf' | translate }}</label>
					<input
						[(ngModel)]="_travel.maxgenderf"
						#maxgenderf="ngModel"
						name="maxgenderf"
						type="number"
						class="form-control"
						id="maxgenderf"
						placeholder="{{ 'labels.maxgenderf' | translate }}"
						[ngClass]="{'is-invalid': editForm.submitted && maxgenderf.invalid}">
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && maxgenderf.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>

				<div class="form-group col-md-3">
					<label for="name">{{ 'labels.happened' | translate }}*</label>
					<select
						[(ngModel)]="_travel.happened"
						#status="ngModel"
						name="happened"
						required
						class="form-control"
						id="happened"
						[ngClass]="{'is-invalid': editForm.submitted && status.invalid}">
						<option [value]="0">{{'types.happened.0' | translate}}</option>
						<option [value]="1">{{'types.happened.1' | translate}}</option>
					</select>
					<div
						class="invalid-feedback"
						*ngIf="editForm.submitted && maxguests.invalid">
						{{'errors.required' | translate}}
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-3">
					<label>{{'labels.departure' | translate}}</label>
					<app-datepicker
						name="begindate"
						required
						[(ngModel)]="_travel.begindate">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>{{'labels.return' | translate}}</label>
					<app-datepicker
						name="enddate"
						required
						[(ngModel)]="_travel.enddate">
					</app-datepicker>
				</div>
				<div class="col-md-3">
					<label>{{'labels.reviewer' | translate}}</label>
					<input
						type="text"
						name="reviewer"
						class="form-control"
						disabled
						[value]="_travel.reviewer?.fullname">
				</div>
				<div class="col-md-3">
					<label>{{'labels.reviewdate' | translate}}</label>
					<app-datepicker
						name="reviewdate"
						[disabled]="true"
						[(ngModel)]="_travel.reviewdate">
					</app-datepicker>
				</div>
				<div class="col-md-3 pt-4 pb-3">
					<input
						type="checkbox"
						name="virtualtravel"
						[(ngModel)]="_travel.virtualtravel">
					{{'labels.virtualtravel' | translate}}
				</div>
				<div class="col-md-3 pt-4 pb-3">
					<input
						type="checkbox"
						name="incognito"
						[(ngModel)]="_travel.incognito">
					{{'labels.incognito' | translate}}
				</div>
				<div class="col-md-3 pt-4 pb-3">
					<input
						type="checkbox"
						name="secret"
						[(ngModel)]="_travel.secret">
					{{'labels.secret' | translate}}
				</div>
				<div class="col-md-3 pt-4 pb-3">
					<a
						target="_blank"
						[href]="'https://share.meetravel.it/share.html?opaqueid='+_travel.opaqueid">
						Link Share
					</a>
				</div>
			</div>
			<div class="row">
				<label>{{'labels.description' | translate}}</label>
				<textarea
					#description="ngModel"
					name="description"
					required
					rows="3"
					class="form-control"
					id="description"
					[(ngModel)]="_travel.description"
					[ngClass]="{'is-invalid': editForm.submitted && description.invalid}"></textarea>
			</div>
			<hr>
			<div>
				<h5>{{'labels.types' | translate}}</h5>
				<br>
				<app-travel-types
					#travelTypeList
					[travel]="_travel"></app-travel-types>

			</div>
			<hr>
			<div>
				<h5>{{'labels.accomodations' | translate}}</h5>
				<br>
				<app-travel-accomodation [travel]="_travel">{{'labels.accomodation' | translate}}</app-travel-accomodation>
			</div>

			<div cdkDropList>

				<div
					cdkDrag
					class="row">

					<div class="col-md-6">
						<app-media-upload
							(done)="update($event)"
							[info]
							[url]="'/ws/travel/upload/'+_travel?.id"
							[entityClass]="travelClass"
							[id]="_travel?.id"></app-media-upload>
					</div>
					<div class="col-md-6">
						<img
							asyncimg
							[opaque]="_travel?.img"
							[alt]
							placeholder="/assets/images/travel-placeholder.png"
							size="md"
							class="img-fluid mt-3">
					</div>

				</div>

			</div>
			<br>
			<br>
			<div class="modal-footer">
				<button
					type="button"
					*ngIf="_travel?.isSubmitted"
					class="btn btn-success float-left"
					(click)="accept()">
					{{'labels.accept' | translate}}
				</button>
				<button
					type="submit"
					class="btn btn-primary">
					{{'labels.save' | translate}}
				</button>
				<button
					type="reset"
					class="btn btn-secondary">
					{{'labels.reset' | translate}}
				</button>
				<button
					*ngIf="_travel?.isSubmitted"
					type="button"
					(click)="decline()"
					class="btn btn-danger">
					{{'labels.decline' | translate}}
				</button>

			</div>
		</form>
	</div>
</div>
